import { MouseEvent, ReactElement, ReactNode, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SystemStyleObject } from '@mui/system';
import { Box, Button, Grow, IconButton } from '@mui/material';
import { Styles } from 'common/types/styles';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SwiperType from 'swiper';
import CloseIcon from '@mui/icons-material/Close';
import { theme } from 'common/constants/theme';
import { ApproveModal } from 'common/ui/shared/approve-modal';
import { approveDeleteVocabulary } from 'common/constants/vocabulary';
import 'swiper/css';
import FlagIcon from '@mui/icons-material/Flag';
import { LightTooltip } from '../light-tooltip';

interface GetImageStylesProps {
  image: string;
  height?: number | string;
  width?: number | string;
  selected?: boolean;
  showZoom?: boolean;
}

const getImageStyles = ({ image, width, height, selected, showZoom }: GetImageStylesProps): SystemStyleObject => ({
  backgroundColor: '#fff',
  backgroundImage: `url("${image}")`,
  backgroundSize: 'contain',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  height: height || 'auto',
  width: width || 'auto',
  border: selected ? `1px solid ${theme.palette.grey[500]}` : 'none',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  cursor: showZoom ? 'zoom-in' : undefined,
});

interface StylesProps {
  isZoomed: boolean;
  image: string;
}

const getStyles = ({ isZoomed, image }: StylesProps): Styles => ({
  container: { width: '100%', display: 'flex', flexDirection: 'column', gap: 1 },
  controls: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
  previewImage: { cursor: 'pointer' },
  zoomedContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: '#fff',
    zIndex: 9999,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
  },
  buttonWrapper: {
    height: '100%',
    width: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    ':hover': { backgroundColor: '#c1c1c13e' },
  },
  imageWrapper: {
    backgroundImage: `url("${image}")`,
    backgroundColor: '#fff',
    cursor: isZoomed ? 'zoom-out' : undefined,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: '100%',
    width: '100%',
  },
  productContentFlag: { position: 'absolute', top: 1, right: 1, zIndex: 999 },
  closeBtn: { position: 'absolute', top: 16, left: '50%', transform: 'translateX(-50%)' },
});

interface Props {
  images: string[];
  isDeleteAvailable?: boolean;
  isFlagAvailable?: boolean;
  onDelete?: (value: string) => void;
  renderInfo?: (currentIndex: number, swiped: SwiperType) => ReactNode;
  renderFlag?: (currentIndex: number) => ReactNode;
}

export function ImagesSlider({
  images,
  isDeleteAvailable,
  isFlagAvailable,
  onDelete,
  renderInfo,
  renderFlag,
}: Props): ReactElement {
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isZoomed, setIsZoomed] = useState<boolean>(false);

  const styles = getStyles({ isZoomed, image: images[currentIndex] });

  const mainSwiper = useRef<SwiperType>();
  const previewSwiper = useRef<SwiperType>();

  const handleZoom = () => {
    setIsZoomed(isZoomed => !isZoomed);
  };

  const handleCloseClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsZoomed(false);
  };

  const handleCloseConfirmDelete = () => {
    setIsConfirmDeleteOpen(false);
  };

  const handleOpenConfirmDelete = () => {
    setIsConfirmDeleteOpen(true);
  };

  const handleConfirmDelete = () => {
    onDelete(images[currentIndex]);
    handleCloseConfirmDelete();
  };

  return (
    <Box sx={styles.container}>
      <div>
        <Swiper
          slidesPerView={1}
          direction="horizontal"
          spaceBetween={50}
          onSwiper={(swiper: SwiperType) => {
            mainSwiper.current = swiper;
          }}
          onActiveIndexChange={(swiper: SwiperType) => {
            previewSwiper.current?.slideTo(swiper.activeIndex);
            setCurrentIndex(swiper.activeIndex);
          }}
        >
          {images.map(image => (
            <SwiperSlide key={image}>
              <Box
                role="img"
                sx={getImageStyles({ image, width: '100%', height: 250, showZoom: true })}
                onClick={handleZoom}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <Box sx={styles.controls}>
        <IconButton onClick={() => mainSwiper.current?.slidePrev()}>
          <ArrowBackIcon />
        </IconButton>
        <Box>
          {isFlagAvailable && (renderFlag ? renderFlag(currentIndex) : <FlagIcon />)}
          {isDeleteAvailable && (
            <LightTooltip title="Delete Image" placement="top">
              <IconButton sx={styles.deleteButton} className="delete-button" onClick={handleOpenConfirmDelete}>
                <ClearIcon fontSize="small" />
              </IconButton>
            </LightTooltip>
          )}
        </Box>
        <IconButton onClick={() => mainSwiper.current?.slideNext()}>
          <ArrowForwardIcon />
        </IconButton>
      </Box>
      {renderInfo && renderInfo(currentIndex, mainSwiper.current)}
      <div>
        <Swiper
          slidesPerView={3}
          direction="horizontal"
          spaceBetween={8}
          onSwiper={(swiper: SwiperType) => {
            previewSwiper.current = swiper;
          }}
        >
          {images.map((image, index) => (
            <SwiperSlide
              key={image}
              onClick={() => {
                mainSwiper.current?.slideTo(index);
                previewSwiper.current?.slideTo(index);
              }}
            >
              <Box
                sx={[
                  getImageStyles({ image, height: 70, width: '100%', selected: index === currentIndex }),
                  styles.previewImage,
                ]}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <Grow in={isZoomed}>
        <Box sx={styles.zoomedContainer}>
          <Box sx={styles.buttonWrapper} onClick={() => mainSwiper.current?.slidePrev()}>
            <ArrowBackIcon />
          </Box>
          <Box sx={styles.imageWrapper} onClick={handleZoom} />
          <Box sx={styles.buttonWrapper} onClick={() => mainSwiper.current?.slideNext()}>
            <Button color="inherit" sx={styles.closeBtn} onClick={handleCloseClick}>
              <CloseIcon />
              Close
            </Button>
            <ArrowForwardIcon />
          </Box>
        </Box>
      </Grow>
      <ApproveModal
        open={isConfirmDeleteOpen}
        onClose={handleCloseConfirmDelete}
        onConfirm={handleConfirmDelete}
        vocabulary={{
          ...approveDeleteVocabulary,
          description: 'Are you sure you want to delete this image?',
        }}
      />
    </Box>
  );
}
