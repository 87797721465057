import { ExtendedContentEntry, FormAttributeEntry, TemplateRule } from 'common/types/common';
import { ProductSaveBody } from 'common/services/api/content/content-api.types';
import {
  addMeasureForEachElementOfValue,
  compareArrays,
  extractBuggyAttributeValue,
  splitStringToArray,
} from 'common/utils/arrays';
import { replaceNumber, replaceUnits } from 'common/utils/units';
import { RetailerType } from 'common/constants/entities';
import { UserService, userService } from './user.service';

const separators = [';'];
const extendedSeparators = [...separators, ','];

// Bigger value = more important
enum WeightImportance {
  Requirement = 1,
  AttributeWeight,
  Scope,
}

function getScopeWeight(inScope: boolean) {
  return inScope ? WeightImportance.Scope : 0;
}

function getRequirementWeight(requirementLevel: string) {
  return requirementLevel === 'Required' ? WeightImportance.Requirement : 0;
}

export function compareAttributesRules(aRules: TemplateRule, bRules: TemplateRule) {
  const a =
    getScopeWeight(aRules.settings.in_scope) +
    getRequirementWeight(aRules.requirement_level) +
    (aRules.weight?.weight ?? 0) * WeightImportance.AttributeWeight;
  const b =
    getScopeWeight(bRules.settings.in_scope) +
    getRequirementWeight(bRules.requirement_level) +
    (bRules.weight?.weight ?? 0) * WeightImportance.AttributeWeight;

  if (a !== b) {
    return b - a;
  }

  const alphabeticalOrder = aRules.key.localeCompare(bRules.key);
  return alphabeticalOrder;
}

export function extractAttributeFromBackend(key: string, rules: TemplateRule, product: ExtendedContentEntry) {
  const definedAttributes = product.content?.definedAttributes || {};
  const mappedAttributes = product.content?.mappedAttributes;
  const externalAttributes = product.content?.externalAttributes || {};
  const initialAttributes = product.content?.processed || {};
  const humanAttributes = product.human_content?.processed || {};
  const aiAttributes = product.ai_content?.processed || {};
  const computedAttributes = product.computed_content?.processed || {};

  const isSelect = (rules.closed_list === 'Yes' || rules.multi_select === 'Yes') && rules.data_type !== 'MEASURE';

  const definedAttrKey = (mappedAttributes?.find(ma => ma[0] === key) || [])[1];
  const definedValue = definedAttrKey ? extractBuggyAttributeValue(definedAttributes[definedAttrKey]) : '';
  const externalValue = definedAttrKey ? extractBuggyAttributeValue(externalAttributes[definedAttrKey]) : '';

  let initialValue = extractBuggyAttributeValue(initialAttributes[key]?.value);
  if (!isSelect && initialAttributes[key]?.unit_of_measure) {
    initialValue = addMeasureForEachElementOfValue(initialValue, initialAttributes[key]?.unit_of_measure) ?? '';
  }
  const initialError = initialAttributes[key]?.message;

  let humanValue = extractBuggyAttributeValue(humanAttributes[key]?.value);
  if (!isSelect && humanAttributes[key]?.unit_of_measure) {
    humanValue = addMeasureForEachElementOfValue(humanValue, humanAttributes[key]?.unit_of_measure) ?? '';
  }
  const humanError = humanAttributes[key]?.message;
  const humanSkipped = humanAttributes[key]?.skipped;

  let aiValue = extractBuggyAttributeValue(aiAttributes[key]?.value);
  if (!isSelect && aiAttributes[key]?.unit_of_measure) {
    aiValue = addMeasureForEachElementOfValue(aiValue, aiAttributes[key]?.unit_of_measure) ?? '';
  }

  let computedValue = extractBuggyAttributeValue(computedAttributes[key]?.value);
  if (!isSelect && computedAttributes[key]?.unit_of_measure) {
    computedValue = addMeasureForEachElementOfValue(computedValue, computedAttributes[key]?.unit_of_measure) ?? '';
  }

  const acceptableValues = rules.acceptable_values ? rules.acceptable_values?.split(';') : undefined;
  const defaultValue = extractBuggyAttributeValue(rules.settings?.default_value);
  const aiModel = extractBuggyAttributeValue(aiAttributes[key]?.aiModel);

  const extractAcceptableString = (value: string, unitOfMeasures?: string) => {
    if (!acceptableValues || (rules.data_type === 'MEASURE' && acceptableValues.includes(unitOfMeasures))) return value;
    return acceptableValues.includes(value.trim()) ? value : '';
  };

  const extractValue = (
    value: string,
    config: { keepEmpty: boolean; separators: string[]; unitOfMeasures?: string }
  ) => {
    if (value === undefined || value === null) return null;
    const extractedValue =
      rules.multi_select === 'Yes'
        ? splitStringToArray(value, config.separators).filter(value => {
            if (
              !acceptableValues ||
              (rules.data_type === 'MEASURE' && acceptableValues.includes(config?.unitOfMeasures))
            )
              return true;
            return acceptableValues.includes(value.trim());
          })
        : extractAcceptableString(String(value), config?.unitOfMeasures);
    return extractedValue.length || config.keepEmpty ? extractedValue : null;
  };

  return {
    key,
    rules,
    definedValue,
    externalValue,
    initialValue,
    humanValue,
    aiValue,
    aiModel,
    computedValue,
    defaultValue,
    weight: initialAttributes[key]?.weight,
    score: initialAttributes[key]?.score,
    backendError: humanValue ? humanError : initialError,
    skipped: Boolean(humanSkipped),
    formValue:
      extractValue(humanValue, {
        keepEmpty: true,
        separators,
        unitOfMeasures: humanAttributes[key]?.unit_of_measure,
      }) ??
      extractValue(aiValue, {
        keepEmpty: false,
        separators: extendedSeparators,
        unitOfMeasures: aiAttributes[key]?.unit_of_measure,
      }) ??
      extractValue(computedValue, {
        keepEmpty: false,
        separators,
        unitOfMeasures: computedAttributes[key]?.unit_of_measure,
      }) ??
      extractValue(initialValue, {
        keepEmpty: false,
        separators,
        unitOfMeasures: initialAttributes[key]?.unit_of_measure,
      }) ??
      extractValue(defaultValue, { keepEmpty: false, separators }) ??
      (rules.multi_select === 'Yes' ? [] : ''),
  };
}

interface TransformAttributesFromBackendArgs {
  category: Record<string, TemplateRule>;
  product: ExtendedContentEntry;
}

export function transformAttributesFromBackend({
  category,
  product,
}: TransformAttributesFromBackendArgs): Array<FormAttributeEntry> {
  return Object.entries(category)
    .map(([key, rules]) => {
      return extractAttributeFromBackend(key, rules, product);
    })
    .sort((a, b) => compareAttributesRules(a.rules, b.rules));
}

export function parseAttributeValueForBackend(value: string | string[]) {
  return Array.isArray(value) ? value.join(';') : value;
}

export function transformAttributesForBackend(attributes: Array<FormAttributeEntry>): ProductSaveBody {
  return {
    attributes: attributes.reduce((acc, item) => {
      const isMeasure = item.rules.data_type === 'MEASURE';
      const units =
        (userService.getRetailer() === RetailerType.Walmart || userService.getRetailer() === RetailerType.Flywheel
          ? item.rules.acceptable_units
          : item.rules.acceptable_values
        )?.split(/,|;/) ?? [];
      const parsedValue = parseAttributeValueForBackend(item.formValue);
      const value = isMeasure ? replaceUnits(parsedValue, units) : parsedValue;
      const unit_of_measure = isMeasure ? replaceNumber(parsedValue, units) : undefined;
      acc[item.key] = {
        value,
        ...(unit_of_measure ? { unit_of_measure } : {}),
      };
      return acc;
    }, {} as Record<string, { value: string }>),
  };
}

export function extractDirtyAttributes(
  initialAttributes: Array<FormAttributeEntry>,
  dirtyAttributes: Array<FormAttributeEntry>
): Array<FormAttributeEntry> {
  const changedAttributes = dirtyAttributes.filter(dirtyAttribute => {
    const initialAttribute = initialAttributes.find(attr => attr.key === dirtyAttribute.key);
    if (!initialAttribute) return false;
    return Array.isArray(dirtyAttribute.formValue)
      ? !compareArrays(dirtyAttribute.formValue as string[], initialAttribute.formValue as string[])
      : dirtyAttribute.formValue !== initialAttribute.formValue;
  });

  return changedAttributes;
}

export function getPDPValues(from: ExtendedContentEntry, retailer?: RetailerType) {
  const amazonFeatureArr = from?.bullet_point_value;
  const amazonFeature = Array.isArray(amazonFeatureArr) ? amazonFeatureArr.join('\n') : amazonFeatureArr;

  const localUserService = new UserService();
  localUserService.setRetailer(retailer ?? userService.getRetailer());

  return {
    ai: {
      title:
        from?.human_content?.processed?.optimized_product_title?.value ??
        (userService.ensureRetailer(RetailerType.Walmart)
          ? from?.ai_content?.optimizations?.optimized?.title?.revised_value
          : null) ??
        from?.ai_content?.optimizations?.optimized?.title?.value ??
        '',
      desc:
        from?.human_content?.processed?.optimized_product_short_description?.value ??
        (userService.ensureRetailer(RetailerType.Walmart)
          ? from?.ai_content?.optimizations?.optimized?.description?.revised_value
          : null) ??
        from?.ai_content?.optimizations?.optimized?.description?.value ??
        '',
      feats:
        from?.human_content?.processed?.optimized_product_long_description?.value ??
        (userService.ensureRetailer(RetailerType.Walmart)
          ? from?.ai_content?.optimizations?.optimized?.features?.revised_value
          : null) ??
        from?.ai_content?.optimizations?.optimized?.features?.value ??
        '',
    },
    seo: {
      title:
        from?.human_content?.processed?.seo_optimized_product_title?.value ??
        from?.ai_content?.optimizations?.seo_optimized?.title?.value ??
        '',
      desc:
        from?.human_content?.processed?.seo_optimized_product_short_description?.value ??
        from?.ai_content?.optimizations?.seo_optimized?.description?.value ??
        '',
      feats:
        from?.human_content?.processed?.seo_optimized_product_long_description?.value ??
        from?.ai_content?.optimizations?.seo_optimized?.features?.value ??
        '',
    },
    initial: {
      title: localUserService.ensureRetailers([RetailerType.Walmart, RetailerType.Flywheel])
        ? from?.content?.definedAttributes?.product_name
        : from?.name || from?.title,
      desc:
        String(
          localUserService.ensureRetailers([RetailerType.Walmart, RetailerType.Flywheel])
            ? from?.content?.definedAttributes?.product_short_description ??
                from?.content?.definedAttributes?.short_description
            : from?.description ?? from?.product_details
        ) || '',
      feats:
        String(
          localUserService.ensureRetailers([RetailerType.Walmart, RetailerType.Flywheel])
            ? from?.content?.definedAttributes?.product_long_description ??
                from?.content?.definedAttributes?.product_description
            : from?.feature_bullets ?? from?.features ?? amazonFeature
        ) || '',
    },
  };
}
