import { AxiosResponse } from 'axios';
import { RetailerType } from 'common/constants/entities';
import { ApiService } from 'common/services';
import {
  CreatePromptBody,
  GetListPrompts,
  GetNormalizationConfigResponse,
  GetSelectedPromptResponse,
  GetSelectModelParams,
  GetSelectRetailerModelResponse,
  GetTemplateValues,
  PromptFilters,
  ResetModelBody,
  SelectAttributeModelBody,
  SelectModelBody,
  SelectVersionPromptBody,
  TemplateValuesFilters,
} from './ai-prompts.types';

class AIPromptsApiService extends ApiService {
  getWalmartSelectedPrompt = (params: PromptFilters): Promise<AxiosResponse<GetSelectedPromptResponse>> => {
    return this.get('/ai-prompts/selected', { params });
  };

  getRetailerSelectedPrompt = (
    retailer: RetailerType,
    params: PromptFilters
  ): Promise<AxiosResponse<GetSelectedPromptResponse>> => {
    return this.get(`/vendors/${retailer.toLowerCase()}/ai-prompts/selected`, { params });
  };

  getWalmartListPrompts = (params: PromptFilters): Promise<AxiosResponse<GetListPrompts>> => {
    return this.get('/ai-prompts', { params });
  };

  getRetailerListPrompts = (retailer: RetailerType, params: PromptFilters): Promise<AxiosResponse<GetListPrompts>> => {
    return this.get(`/vendors/${retailer.toLowerCase()}/ai-prompts`, { params });
  };

  getWalmartTemplateValues = (params: TemplateValuesFilters): Promise<AxiosResponse<GetTemplateValues>> => {
    return this.get('/ai-prompts/template-values', { params });
  };

  getRetailerTemplateValues = (
    retailer: RetailerType,
    params: TemplateValuesFilters
  ): Promise<AxiosResponse<GetTemplateValues>> => {
    return this.get(`/vendors/${retailer.toLowerCase()}/ai-prompts/template-values`, { params });
  };

  createWalmartPrompt = (data: CreatePromptBody): Promise<AxiosResponse<GetSelectedPromptResponse>> => {
    return this.post('/ai-prompts', data);
  };

  createRetailerPrompt = (
    retailer: RetailerType,
    data: CreatePromptBody
  ): Promise<AxiosResponse<GetSelectedPromptResponse>> => {
    return this.post(`/vendors/${retailer.toLowerCase()}/ai-prompts`, data);
  };

  selectWalmartPrompt = (data: SelectVersionPromptBody): Promise<AxiosResponse<GetSelectedPromptResponse>> => {
    return this.post('/ai-prompts/select', data);
  };

  selectRetailerPrompt = (
    retailer: RetailerType,
    data: SelectVersionPromptBody
  ): Promise<AxiosResponse<GetSelectedPromptResponse>> => {
    return this.post(`/vendors/${retailer.toLowerCase()}/ai-prompts/select`, data);
  };

  getSelectWalmartModel = (params: GetSelectModelParams): Promise<AxiosResponse<GetSelectRetailerModelResponse>> => {
    return this.get('/ai-models/selected', { params });
  };

  getSelectRetailerModel = (
    retailer: RetailerType,
    params: GetSelectModelParams
  ): Promise<AxiosResponse<GetSelectRetailerModelResponse>> => {
    return this.get(`/vendors/${retailer.toLowerCase()}/ai-models/selected`, { params });
  };

  selectWalmartModel = (body: SelectModelBody): Promise<AxiosResponse<GetSelectedPromptResponse>> => {
    return this.post('/ai-models/select', body);
  };

  selectRetailerModel = (
    retailer: RetailerType,
    body: SelectModelBody
  ): Promise<AxiosResponse<GetSelectedPromptResponse>> => {
    return this.post(`/vendors/${retailer.toLowerCase()}/ai-models/select`, body);
  };

  selectWalmartAttributeModel = (body: SelectAttributeModelBody) => {
    return this.post('/ai-models/attribute-models/select', body);
  };

  selectAttributeModel = (retailer: RetailerType, body: SelectAttributeModelBody) => {
    return this.post(`/vendors/${retailer.toLowerCase()}/ai-models/attribute-models/select`, body);
  };

  resetWalmartModel = () => {
    return this.post('/ai-models/attribute-models/reset');
  };

  resetRetailerModel = (retailer: RetailerType, body: ResetModelBody) => {
    return this.post(`/vendors/${retailer.toLowerCase()}/ai-models/attribute-models/reset`, body);
  };

  getNormalizationConfig = (): Promise<AxiosResponse<GetNormalizationConfigResponse>> => {
    return this.get('/config?vendor=Autozone');
  };

  patchNormalizationConfig = (body: GetNormalizationConfigResponse) => {
    return this.patch('/config', body);
  };
}

export const aiPromptsApi = new AIPromptsApiService(process.env.REACT_APP_AI_API);
