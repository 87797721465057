import { Typography } from '@mui/material';
import { Styles } from 'common/types/styles';
import { stringToHEX } from 'common/utils/color';
import { ReactElement, useEffect, useMemo, useRef } from 'react';
import { SystemStyleObject } from '@mui/system';
import { getKWsRegex } from './regex';

const styles: Styles = {
  text: { fontSize: 14, whiteSpace: 'pre-wrap' },
};

interface Props {
  keywords: string[];
  value: string;
  highlightedKW?: string;
  sx?: SystemStyleObject;
}

export function SeoComparator({ keywords, value, highlightedKW, sx }: Props): ReactElement {
  const ref = useRef<HTMLParagraphElement>();

  const parsedValue = useMemo(() => {
    let str = value;

    const wrapper = (str: string, color: string) => `<span style="background-color: ${color}">${str}</span>`;

    if (highlightedKW) {
      str = str.replace(getKWsRegex(highlightedKW), match => wrapper(match, stringToHEX(highlightedKW)));
    }

    [...keywords]
      .sort((a, b) => b.length - a.length)
      .forEach(k => {
        str = str.replace(getKWsRegex(k), match => wrapper(match, 'yellow'));
      });
    return str;
  }, [highlightedKW, keywords, value]);

  useEffect(() => {
    ref.current.innerHTML = parsedValue;
  }, [parsedValue]);

  return <Typography component="div" ref={ref} sx={[styles.text, sx].filter(Boolean)} />;
}
